<template>
  <div>
    <v-card class="ma-1 pa-1">
      <v-card-title class="pa-1">
        <span class="mx-4">{{ $t("tabel") }}</span>
        <v-spacer></v-spacer>

        <v-form ref="myForm">
          <v-row>
            <v-col cols="6">
              <v-menu
                ref="menu"
                v-model="menu"
                :close-on-content-click="false"
                :return-value.sync="date"
                transition="scale-transition"
                offset-y
                max-width="290px"
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field v-model="date" readonly v-bind="attrs" v-on="on" hide-details></v-text-field>
                </template>
                <v-date-picker v-model="date" type="month" no-title scrollable>
                  <v-spacer></v-spacer>
                  <v-btn text color="primary" @click="$refs.menu.save(date)">OK</v-btn>
                  <v-btn text color="primary" @click="menu = false">{{ $t("cancel") }}</v-btn>
                </v-date-picker>
              </v-menu>
            </v-col>
            <v-btn color="success" class="mt-3" @click="getList()">{{ $t("view") }}</v-btn>
          </v-row>
        </v-form>
      </v-card-title>
      <v-sheet height="400">
        <v-calendar
          :start="date+'-01'"
          :weekdays="[1,2,3,4,5,6,0]"
          color="primary"
          style="text-align: center;font-weight: 600;"
        >
          <template v-slot:day="{ past, date, day, month }">
            <span v-if="day == 1 && items.z12mm == date.substr(5, 2)">{{ items.z12d1 }}</span>
            <span v-else-if="day == 2 && items.z12mm == date.substr(5, 2)">{{ items.z12d2 }}</span>
            <span v-else-if="day == 3 && items.z12mm == date.substr(5, 2)">{{ items.z12d3 }}</span>
            <span v-else-if="day == 4 && items.z12mm == date.substr(5, 2)">{{ items.z12d4 }}</span>
            <span v-else-if="day == 5 && items.z12mm == date.substr(5, 2)">{{ items.z12d5 }}</span>
            <span v-else-if="day == 6 && items.z12mm == date.substr(5, 2)">{{ items.z12d6 }}</span>
            <span v-else-if="day == 7 && items.z12mm == date.substr(5, 2)">{{ items.z12d7 }}</span>
            <span v-else-if="day == 8 && items.z12mm == date.substr(5, 2)">{{ items.z12d8 }}</span>
            <span v-else-if="day == 9 && items.z12mm == date.substr(5, 2)">{{ items.z12d9 }}</span>
            <span v-else-if="day == 10 && items.z12mm == date.substr(5, 2)">{{ items.z12d10 }}</span>
            <span v-else-if="day == 11 && items.z12mm == date.substr(5, 2)">{{ items.z12d11 }}</span>
            <span v-else-if="day == 12 && items.z12mm == date.substr(5, 2)">{{ items.z12d12 }}</span>
            <span v-else-if="day == 13 && items.z12mm == date.substr(5, 2)">{{ items.z12d13 }}</span>
            <span v-else-if="day == 14 && items.z12mm == date.substr(5, 2)">{{ items.z12d14 }}</span>
            <span v-else-if="day == 15 && items.z12mm == date.substr(5, 2)">{{ items.z12d15 }}</span>
            <span v-else-if="day == 16 && items.z12mm == date.substr(5, 2)">{{ items.z12d16 }}</span>
            <span v-else-if="day == 17 && items.z12mm == date.substr(5, 2)">{{ items.z12d17 }}</span>
            <span v-else-if="day == 18 && items.z12mm == date.substr(5, 2)">{{ items.z12d18 }}</span>
            <span v-else-if="day == 19 && items.z12mm == date.substr(5, 2)">{{ items.z12d19 }}</span>
            <span v-else-if="day == 20 && items.z12mm == date.substr(5, 2)">{{ items.z12d20 }}</span>
            <span v-else-if="day == 21 && items.z12mm == date.substr(5, 2)">{{ items.z12d21 }}</span>
            <span v-else-if="day == 22 && items.z12mm == date.substr(5, 2)">{{ items.z12d22 }}</span>
            <span v-else-if="day == 23 && items.z12mm == date.substr(5, 2)">{{ items.z12d23 }}</span>
            <span v-else-if="day == 24 && items.z12mm == date.substr(5, 2)">{{ items.z12d24 }}</span>
            <span v-else-if="day == 25 && items.z12mm == date.substr(5, 2)">{{ items.z12d25 }}</span>
            <span v-else-if="day == 26 && items.z12mm == date.substr(5, 2)">{{ items.z12d26 }}</span>
            <span v-else-if="day == 27 && items.z12mm == date.substr(5, 2)">{{ items.z12d27 }}</span>
            <span v-else-if="day == 28 && items.z12mm == date.substr(5, 2)">{{ items.z12d28 }}</span>
            <span v-else-if="day == 29 && items.z12mm == date.substr(5, 2)">{{ items.z12d29 }}</span>
            <span v-else-if="day == 30 && items.z12mm == date.substr(5, 2)">{{ items.z12d30 }}</span>
            <span v-else-if="day == 31 && items.z12mm == date.substr(5, 2)">{{ items.z12d31 }}</span>
          </template>
        </v-calendar>
      </v-sheet>
    </v-card>
    <v-dialog v-model="loading" width="300" hide-overlay>
      <v-card color="primary" dark>
        <v-card-text>
          {{ $t("loadingText") }}
          <v-progress-linear indeterminate color="white" class="mb-0"></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
const axios = require("axios").default;
const moment = require("moment");
export default {
  data() {
    return {
      loading: false,
      employee: {},
      date: new Date().toISOString().substr(0, 7),
      newMonth: new Date().getMonth() + 1,
      menu: false,
      modal: false,
      items: [],
      focus: ""
    };
  },
  computed: {
    screenHeight() {
      return window.innerHeight - 175;
    }
  },
  methods: {
    getList() {
      this.loading = true;
      let user = this.$store.getters.getUser();
      let tabel = user.employee.tabel;
      axios
        .get(
          this.$store.state.AS400_url +
            "api/get-tabel/" +
            tabel +
            "/" +
            this.date
        )
        .then(response => {
          this.items = response.data;
          this.loading = false;
        })
        .catch(error => {
          console.log(error);
          this.loading = false;
        });
    }
  },
  mounted() {
    this.getList();
  }
};
</script>
